// WaGen___HASHKEY__89cbbc47_2024-04-12 14:39:32 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/* ---
新建客户诉求工作单信息
*/
export function khsqService_addKhsqGzdxx(czry,gzdGzdxx,gzdGzsl, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/addKhsqGzdxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				czry : czry, //String
				gzdGzdxx : gzdGzdxx, //GzdGzdxx
				gzdGzsl : gzdGzsl //GzdGzsl
			}
		}
	})
}

/* ---
获取客户诉求工作单信息
*/
export function khsqService_getKhsqGzdxx(ywbh,qdrq, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/getKhsqGzdxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh, //Long
				qdrq : qdrq //String
			}
		}
	})
}

/* ---
审批客户诉求工作单
*/
export function khsqService_approveKhsqGzd(ywbh,gzdSpyj, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/approveKhsqGzd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh, //Long
				gzdSpyj : gzdSpyj //GzdSpyj
			}
		}
	})
}

/* ---
根据搜索条件获取个人待办诉求工单
*/
export function khsqService_getWdxxByQuery(gnid,ywbh,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/getWdxxByQuery',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				gnid : gnid, //String
				ywbh : ywbh, //Long
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}

/* ---
根据搜索条件获取已归档的诉求工单
*/
export function khsqService_getYgdKhsqGzd(ywbh,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/getYgdKhsqGzd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh, //Long
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}

/* ---
通过查询条件获取岗位待办
*/
export function khsqService_getGwxxByQuery(dybm,gnid,ywbh,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/getGwxxByQuery',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				dybm : dybm, //String
				gnid : gnid, //String
				ywbh : ywbh, //Long
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}

/* ---
获取诉求列表并关联工作单信息
*/
export function khsqService_getKhsqListWithGzdxx(jgbm,dybm,ywbh,czry,yhbh,yhmc,sqlx,gzdzt,sqrqq,sqrqz,gdzt,pageNo,pageSize, meta) {
	return fetch({
		url : 'psdmskhfw/service/KhsqService/getKhsqListWithGzdxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				ywbh : ywbh, //Long
				czry : czry, //String
				yhbh : yhbh, //Long
				yhmc : yhmc, //String
				sqlx : sqlx, //String
				gzdzt : gzdzt, //List<String>
				sqrqq : sqrqq, //String
				sqrqz : sqrqz, //String
				gdzt : gdzt, //String
				pageNo : pageNo, //Integer
				pageSize : pageSize //Integer
			}
		}
	})
}